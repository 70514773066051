/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-unused-vars */
<template>
 <Spinner v-if="!allLoaded"/>
 <div v-else>
  <legend>Legenda: </legend>
  <ul>
   <p><span class="event zadaca ms-1 me-1"></span>Zadaca</p>
   <p><span class="event ispit ms-1 me-1"></span>Ispit</p>
   <p><span class="event ispitivanje ms-1 me-1"></span>Ispitivanje</p>
   <p><span class="event prezentacija ms-1 me-1"></span>Prezentacija</p>
   <p><span class="event ostalo ms-1 me-1"></span>Ostalo</p>
   <!-- <div class="border-bottom mb-5"></div> -->
  </ul>

  <div class="calendar">
   <div class="week">
    <p class="ct border-bottom">Pon</p>
    <p class="ct border-bottom">Uto</p>
    <p class="ct border-bottom">Sri</p>
    <p class="ct border-bottom">Cet</p>
    <p class="ct border-bottom">Pet</p>
   </div>
   <div v-if="calendar" class="week">
    <div v-for="(day, day_i) in calendar" :key="day_i">
     <Modal :id="concat('daymodal', day_i)" :title="dayToStr(day)">
      <div v-for="(event, event_i) in day.events" :key="event_i">
       <div class="d-inline-flex">
        <h3>{{ event.type }} | {{ event.title }} | </h3>
        <button @click="deleteEvent(day,event)" v-if="auth" class="ms-1 btn btn-danger" data-bs-toggle="modal" :data-bs-target="concat('#daymodal', day_i)">Delete</button>
       </div>
       <p class="border-bottom">{{ event.desc }}</p>
      </div>
     </Modal>
     <div class="day border mt-2 border-light rounded shadow-sm" :class="{passed: day.past}" data-bs-toggle="modal" :data-bs-target="concat('#daymodal', day_i)">
      <p class="ct border-bottom border-dark mb-1">{{ day.day }}.{{ day.month }}</p>
      <div class="events d-flex justify-content-center">
       <div v-for="(event, event_i) in day.events" :key="event_i">
        <span class="event ms-1 me-1 mb-1 ostalo" :class="event.type"></span>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
 <div style="width: 100px; height: 100px;">
  <ActionButton v-if="auth" data-bs-toggle="modal" data-bs-target="#addModal" />
 </div>
 <Modal id="addModal" title="Ovje bih trebao nesto staviti">
  <form @submit.prevent="addEvent">
   <label class="form-label mt-1">Date</label>
   <input class="form-control" v-model="newEvent.date" type="date" name="my_date" id="my_date" required>
   <label class="form-label mt-1 me-1">Type</label>
   <select name="event" id="event" v-model="newEvent.type" required>
    <option value="zadaca">Zadaca</option>
    <option value="ispit">Ispit</option>
    <option value="ispitivanje">Ispitivanje</option>
    <option value="prezentacija">Prezentacija</option>
    <option value="ostalo">Ostalo</option>
   </select>
   <br>
   <input v-if="newEvent.type == 'ostalo'" v-model="newEvent.ostalo" class="form-control" type="text" name="title" id="title" required>
   <label class="form-label mt-1">Title</label>
   <input class="form-control" type="text" name="title" id="title" v-model="newEvent.title" required>
   <label class="form-label mt-1">Description</label>
   <input class="form-control" type="text" name="description" id="description" v-model="newEvent.desc" required>
   <input type="submit" class="btn btn-primary mt-3 float-end" value="Dodaj">
  </form>
 </Modal>
</template>

<style scoped>

 .week {
     display: grid;
     grid-template-columns: repeat(5, auto);
     grid-gap: 5px;
 }
 .day {
     background-color: rgb(245, 245, 245);
 }
 .passed {
     background-color: rgb(225, 225, 225);
 }
 .ct {
     text-align: center;
 }
 .event {
     height: 10px;
     width: 10px;
     border-radius: 50%;
     display: inline-block;
 }
 .events {
     height: 20px;
 }
 .zadaca {
     background-color: lightgreen !important;
 }
 .ispit {
     background-color: mediumvioletred !important;
 }
 .ispitivanje {
     background-color: lightskyblue !important;
 }
 .prezentacija {
     background-color: orangered !important;
 }
 .ostalo{
     background-color: gray;
 }

</style>

<script>
 import Modal from '@/components/Modal.vue'
 import {unauthFetch, authFetch} from '@/lib/api.js'
 import Spinner from '@/components/Spinner.vue'
 import ActionButton from '@/components/ActionButton.vue'
 import {isAuth, hasPermission} from '@/lib/auth.js'

 export default {
     name: 'About',
     components: {
         Modal,
         Spinner,
         ActionButton,
     },
     data() {
         return {
             loaded: {calendar: false},
             calendar: null,
             auth: isAuth() && hasPermission('calendarEdit'),
             modalVisible: false,
             newEvent: {date: null, type: null, ostalo: null, title: null, desc: null},
         }
     },
     computed: {
         allLoaded: function(){
             if( Object.values(this.loaded).indexOf(false) > -1 ) {
                 return false
             } else {
                 return true
             }
         }
     },
     methods: {
         concat(a, b) {
             return a.toString() + b.toString()
         },
         dayToStr(day){
             return day.day.toString()+'.'+day.month.toString()
         },
         addEvent(e){
             let newEvent = {day: null, month: null, year: null, type: null, title: this.newEvent.title, desc: this.newEvent.desc};
             [newEvent.year, newEvent.month, newEvent.day] = this.newEvent.date.split('-')
             if (this.newEvent.type == 'ostalo'){
                 newEvent.type = this.newEvent.ostalo
             } else {
                 newEvent.type = this.newEvent.type
             }
             console.log(newEvent)
             authFetch('/calendar/edit', 'POST', newEvent)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error.response)
                    })
             this.refresh()
         },
         deleteEvent(calendarDay, calendarEvent){
             console.log(calendarDay, calendarEvent)
             authFetch('/calendar/edit', 'DELETE', {day: calendarDay.day, month: calendarDay.month, year: calendarDay.year, type: calendarEvent.type, title: calendarEvent.title, desc: calendarEvent.desc})
             this.refresh()
         },
         refresh(){
             unauthFetch('/calendar/calendar', 'GET').then((data) => {
                 this.calendar = JSON.parse(data.data)
                 this.loaded.calendar=true
             })
         }
     },
     mounted() {
         this.refresh()
     }
 }
</script>
