<template>
 <div class="modal fade" v-bind:id="id" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
   <div class="modal-content rounded-6 shadow">
    <div class="modal-header">
     <h5 class="modal-title" >{{ title }}</h5>
     <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
     <slot></slot>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
 export default {
     name: 'Modal',
     props: ['id', 'title'],
     data () {
         return {
         }
     },
 }
</script>
