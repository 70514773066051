<!--
     Original code from: Schum123/svelte-loading-spinners
     Github repo: https://github.com/Schum123/svelte-loading-spinners @ d947ebfe168b7a3b7f1b1468449e56f357f0013d
-->
<template>
 <div
     class="wrapper"
     v-bind:style="style">
  <div class="shadow"></div>
 </div>
</template>

<script>
 export default {
     name: 'Spinner',
     props: {
         color: {default: 'FF3E00'},
         unit: {default: 'px'},
         duration: {default: '1.2s'},
         size: {default: '100'},
     },
     computed: {
         style: function(){
             return {'--size': `${this.size}${this.unit}`, '--color': `${this.color}`, '--duration': `${this.duration}`}
         }
     }
 }
</script>


<style scoped>
 .wrapper {
     /* position: relative;
        display: flex;
        justify-content: center;
        align-items: center; */
     position: absolute;
     top:0;
     bottom: 0;
     left: 0;
     right: 0;
     margin: auto;
     width: var(--size);
     height: var(--size);
 }
 .shadow {
     color: var(--color);
     font-size: var(--size);
     overflow: hidden;
     width: var(--size);
     height: var(--size);
     border-radius: 50%;
     margin: 28px auto;
     position: relative;
     transform: translateZ(0);
     animation: load var(--duration) infinite ease,
     round var(--duration) infinite ease;
 }
 @keyframes load {
     0% {
         box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
         0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
     5%,
     95% {
         box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
         0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
     10%,
     59% {
         box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
         -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
         -0.297em -0.775em 0 -0.477em;
     }
     20% {
         box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
         -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
         -0.749em -0.34em 0 -0.477em;
     }
     38% {
         box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
         -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
         -0.82em -0.09em 0 -0.477em;
     }
     100% {
         box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
         0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
 }
 @keyframes round {
     0% {
         transform: rotate(0deg);
     }
     100% {
         transform: rotate(360deg);
     }
 }
</style>
