<template>
 <button class="wrapper rounded-circle d-flex align-items-center justify-content-center btn btn-info">
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-plus-lg" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
  </svg>
 </button>
</template>

<script>
 export default {
     name: 'ActionButton',
     methods: {
     }
 }
</script>

<style scoped>
 .wrapper {
     position: fixed;
     bottom: 1rem;
     right: 1rem;
     width: 65px;
     height: 65px;
 }
</style>
